export const calculatorButtons = [
    {
        type: 'clear',
        className: 'ac',
        text: 'AC',
        value: 'All Clear'
    },
    {
        type: 'clear',
        className: 'c',
        text: 'C',
        value: 'Clear'
    },
    {
        type: 'number',
        className: 'seven',
        text: 7,
        value: 7,
    },
    {
        type: 'number',
        className: 'eight',
        text: 8,
        value: 8,
    },
    {
        type: 'number',
        className: 'nine',
        text: 9,
        value: 9,
    },
    {
        type: 'number',
        className: 'four',
        text: 4,
        value: 4,
    },
    {
        type: 'number',
        className: 'five',
        text: 5,
        value: 5,
    },
    {
        type: 'number',
        className: 'six',
        text: 6,
        value: 6,
    },
    {
        type: 'number',
        className: 'one',
        text: 1,
        value: 1,
    },
    {
        type: 'number',
        className: 'two',
        text: 2,
        value: 2,
    },
    {
        type: 'number',
        className: 'three',
        text: 3,
        value: 3,
    },
    {
        type: 'number',
        className: 'zero',
        text: 0,
        value: 0,
    },
    {
        type: 'operator',
        className: 'multiply',
        text: '\u00d7',
        value: 'Multiply',
    },
    {
        type: 'operator',
        className: 'divide',
        text: '\u00f7',
        value: 'Divide',
    },
    {
        type: 'operator',
        className: 'add',
        text: '+',
        value: 'Add',
    },
    {
        type: 'operator',
        className: 'subtract',
        text: '-',
        value: 'Subtract',
    },
    {
        type: 'enter',
        className: 'equal',
        text: '=',
        value: 'Equal',
    }
]